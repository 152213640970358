import React from "react"
import PropTypes from "prop-types"

import NavBar from "./Navbar"

const HeroHeader = ({ children, heroClass, heroMode, heroSize, navActive }) => {
  return (
    <section className={`hero ${heroMode} ${heroClass} ${heroSize}`}>
      <div className="hero-head">
        <NavBar navActive={navActive} />
        <div className="hero-body">{children}</div>
      </div>
    </section>
  )
}

HeroHeader.propTypes = {
  children: PropTypes.node,
  heroMode: PropTypes.string,
  heroClass: PropTypes.string,
  heroSize: PropTypes.string,
  navActive: PropTypes.string,
}

export default HeroHeader
