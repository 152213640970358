import React, { useState, useEffect } from "react"

import Layout from "../components/Layout"
import HeroHeader from "../components/HeroHeader"
import SEO from "../components/seo"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

const SuportePage = () => {
  const [IP, setIP] = useState("Carregando")

  useEffect(() => {
    fetch(`https://api.ipify.org?format=json`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setIP(resultData.ip)
      })
  }, [])

  return (
    <Layout>
      <SEO title="Contato" />
      <HeroHeader
        heroMode={"is-dark"}
        heroClass={"is-triangulo"}
        heroSize={"is-small"}
        navActive={"ajuda"}
      >
        <div className="container has-text-centered">
          <div className="block is-uppercase is-titulo-hero">
            <h1 className="title is-4">Olá, seu número de IP é:</h1>
            <h2 className="subtitle is-2 has-text-primary">
              <span className={IP === "Carregando" ? "is-blink" : ""}>
                {IP}
              </span>
            </h2>
            <div>
              <h3 className="title is-size-6 is-marginless">
                <a
                  href="https://ping.eu/"
                  target="_blank"
                  className="has-text-primary"
                  rel="noopener noreferrer"
                >
                  Link Alternativo 1
                </a>{" "}
                |{" "}
                <a
                  href="https://www.meuip.com.br/"
                  target="_blank"
                  className="has-text-primary"
                  rel="noopener noreferrer"
                >
                  Link Alternativo 2
                </a>
              </h3>
            </div>
          </div>
        </div>
      </HeroHeader>
      <section id="remoto" className="container m-b-lg">
        <div className="block is-hidden-desktop is-hidden-tablet">
          <h1 className="title is-3 has-text-centered p-t-lg is-uppercase">
            Acesso remoto
          </h1>
          <h2 className="subtitle is-4 has-text-centered">
            Escolha sua plataforma
          </h2>

          <Tabs selectedTabClassName="is-active">
            <div className="tabs is-centered is-medium is-toggle is-toggle-rounded is-fullwidth p-md">
              <TabList>
                <Tab className="tab">
                  <div>
                    <span>Android</span>
                  </div>
                </Tab>
                <Tab className="tab">
                  <div>
                    <span>iOS</span>
                  </div>
                </Tab>
              </TabList>
            </div>
            <div className="container has-text-centered">
              <TabPanel className="tab-content">
                <h1 className="title is-3 p-md">
                  Instale o AnyDesk para o Android
                </h1>
                <a
                  href="https://anydesk.pt/download?os=android"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-large is-dark"
                >
                  Download AnyDesk
                </a>
              </TabPanel>
              <TabPanel className="tab-content">
                <h1 className="title is-3 p-md">
                  Instale o AnyDesk para o iOS
                </h1>
                <a
                  href="https://anydesk.pt/download?os=ios"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-large is-dark"
                >
                  Download AnyDesk
                </a>
              </TabPanel>
            </div>
          </Tabs>
        </div>

        <div className="block is-hidden-mobile">
          <h1 className="title is-3 has-text-centered p-t-lg is-uppercase">
            Acesso remoto
          </h1>
          <h2 className="subtitle is-4 has-text-centered">
            Escolha sua plataforma
          </h2>

          <Tabs selectedTabClassName="is-active">
            <div className="tabs is-centered is-medium is-toggle is-toggle-rounded is-fullwidth p-r-sm p-l-sm">
              <TabList>
                <Tab className="tab">
                  <div>
                    <span>Windows</span>
                  </div>
                </Tab>
                <Tab className="tab">
                  <div>
                    <span>macOS</span>
                  </div>
                </Tab>
                <Tab className="tab">
                  <div>
                    <span>Linux</span>
                  </div>
                </Tab>
                <Tab className="tab">
                  <div>
                    <span>Android</span>
                  </div>
                </Tab>
                <Tab className="tab">
                  <div>
                    <span>iOS</span>
                  </div>
                </Tab>
              </TabList>
            </div>
            <div className="container has-text-centered">
              <TabPanel className="tab-content">
                <h1 className="title is-3 p-md">
                  Instale o AnyDesk para o Windows
                </h1>
                <a
                  href="https://anydesk.pt/download?os=win"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-large is-dark"
                >
                  Download AnyDesk
                </a>
              </TabPanel>
              <TabPanel className="tab-content">
                <h1 className="title is-3 p-md">
                  Instale o AnyDesk para o macOS
                </h1>
                <a
                  href="https://anydesk.pt/download?os=mac"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-large is-dark"
                >
                  Download AnyDesk
                </a>
              </TabPanel>
              <TabPanel className="tab-content">
                <h1 className="title is-3 p-md">
                  Instale o AnyDesk para o Linux
                </h1>
                <a
                  href="https://anydesk.pt/download?os=linux"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-large is-dark"
                >
                  Download AnyDesk
                </a>
              </TabPanel>
              <TabPanel className="tab-content">
                <h1 className="title is-3 p-md">
                  Instale o AnyDesk para o Android
                </h1>
                <a
                  href="https://anydesk.pt/download?os=android"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-large is-dark"
                >
                  Download AnyDesk
                </a>
              </TabPanel>
              <TabPanel className="tab-content">
                <h1 className="title is-3 p-md">
                  Instale o AnyDesk para o iOS
                </h1>
                <a
                  href="https://anydesk.pt/download?os=ios"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-large is-dark"
                >
                  Download AnyDesk
                </a>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </section>
    </Layout>
  )
}

export default SuportePage
